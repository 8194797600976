import styled from '@emotion/styled';
import { Box, Space } from '@stenajs-webui/core';
import { Banner, Cardy } from '@stenajs-webui/elements';
import { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BookingDetailsModalWrapper } from '../../../../common-ui/BookingDetailsModalWrapper';
import {
  InformationMessage,
  InformationMessageType,
  resolveInformationMessageBannerVariant,
} from '../../../../common-ui/information-message/informationMessageUtils';
import { BookingColumn, BookingColumnGrid } from '../../../../common-ui/layout/BookingColumnGrid';
import { FlatButtonLink } from '../../../../common-ui/Link';
import { NoSailingSelected } from '../../../../common-ui/sailing-info/NoSailingSelected';
import { SailingInfoHorizontal } from '../../../../common-ui/sailing-info/SailingInfoHorizontal';
import { animalDropdownNoOption } from '../../../../common/graphql/useAnimalTypesOptions';
import { routeCreators } from '../../../../common/routes/AppRoutes';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { useUpdateFormRevertChanges } from '../../../../common/update-information/hooks/useUpdateFormRevertChanges';
import { useUpdateFormState } from '../../../../common/update-information/hooks/useUpdateFormState';
import { UPDATE_SHOW } from '../../../../common/update-information/utils/getUpdateSubmitInformation';
import { ColumnId } from '../../../../gql/graphql';
import { setSaveAsWaitList } from '../../../../services/update-form/updateFormActions';
import { UpdateFormIdVariant } from '../../../../services/update-form/updateFormReducer';
import { breakpoints } from '../../../../themes/defaultTheme';
import {
  getFieldsToEdit,
  getFieldsToShow,
  getHazardousGoodsApprovalInfo,
  isAnyVisible,
} from '../utils/detailsUtils';
import { transformUpdateBookingFormStateToMinimalBooking } from '../utils/minimalBooking';
import { resolveMiscInformation } from '../utils/resolveMiscInformation';
import { shouldShowHazGoodsPaymentInformation } from '../utils/showPaymentUtil';
import { BookingUpdateStatusMessage } from './BookingUpdateStatusMessage';
import { CargoDetailsSection } from './sections/CargoDetailsSection';
import { ImportExportDetailsSection } from './sections/ImportExportDetailsSection';
import { LoadingDetailsSection } from './sections/LoadingDetailsSection';
import { SailingDetailsSection } from './sections/SailingDetailsSection';
import { VehicleDetailsSection } from './sections/VehicleDetailsSection';
import { Texts } from '../../../../common/texts';

export interface BookingDetailsEditProps {
  bookingNo: number;
  onStopEdit: () => void;
  statusInformationList: InformationMessage[];
  children?: ReactNode;
}

export const FieldGrid = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: calc(2 * var(--swui-metrics-space));
`;

export const DimensionsFieldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: calc(2 * var(--swui-metrics-space));

  @media (min-width: ${breakpoints.md}) {
    grid-auto-flow: row;
  }
`;

const formId: UpdateFormIdVariant = 'Details';

export const BookingDetailsEdit: FC<BookingDetailsEditProps> = ({
  bookingNo,
  onStopEdit,
  statusInformationList,
  children,
}) => {
  const dispatch = useDispatch();

  const revertChanges = useUpdateFormRevertChanges({
    bookingNo,
    formId,
  });

  const { booking, formState, submitting, updateSubmitInformation, submit } = useUpdateFormState({
    bookingNo,
    formId,
  });

  useEffect(() => {
    if (
      updateSubmitInformation.status === UPDATE_SHOW.SUCCESS ||
      updateSubmitInformation.status === UPDATE_SHOW.INFORMATION
    ) {
      onStopEdit();
    }
  }, [onStopEdit, updateSubmitInformation]);

  const updateBookingToWaitlist = () => {
    dispatch(setSaveAsWaitList({ bookingNo, formId }));
    submit();
    trackEvent({ category: formId, action: 'SaveToWaitList' });
  };

  if (!booking || !formState) {
    return null;
  }

  const fieldsToShow = getFieldsToShow(
    transformUpdateBookingFormStateToMinimalBooking(formState),
    Boolean(formState.customer?.isCashCustomer),
  );

  const fieldsToEnable = getFieldsToEdit(fieldsToShow, booking.editableFields);

  const showCargoSection = isAnyVisible(fieldsToShow, [
    ColumnId.LivestockTypeCode,
    ColumnId.HazardousGoods,
    ColumnId.CargoWeight,
    ColumnId.Temperature,
    ColumnId.SenderCountryCode,
    ColumnId.ReceiverCountryCode,
  ]);

  const showImportExportSection = isAnyVisible(fieldsToShow, [
    ColumnId.CustomsClassification,
    ColumnId.ImportReference,
    ColumnId.ExportReference,
  ]);

  const hasLivestock = formState.livestockType?.value !== animalDropdownNoOption.value;
  const hasHazardousGoods = Boolean(formState.hazardousGoods);
  const hasUnsavedHazGoods = hasHazardousGoods && !booking.hazardousGoods;
  const hasPlugins = formState.noOfPlugins !== undefined && formState.noOfPlugins > 0;

  const hazardousGoodsApprovalInfo = getHazardousGoodsApprovalInfo(
    booking.hazardousGoods,
    booking.hazardousGoodsApproved,
    booking.bookingStatus.status,
  );
  const miscInformation = resolveMiscInformation(hasLivestock, hasUnsavedHazGoods);

  const showHazGoodsPaymentInformation = shouldShowHazGoodsPaymentInformation(booking);

  return (
    <BookingDetailsModalWrapper>
      <Box indent={3}>
        {formState.sailing ? (
          <SailingInfoHorizontal
            releasedAt={null}
            hasArrived={booking.hasArrived}
            sailing={formState.sailing}
            bookingStatus={booking.bookingStatus.status}
          />
        ) : (
          <NoSailingSelected />
        )}
      </Box>
      <Space />

      <Box width={'100%'} gap>
        <InitialGapHack />
        {hasHazardousGoods && hazardousGoodsApprovalInfo && (
          <Banner
            variant={resolveInformationMessageBannerVariant(hazardousGoodsApprovalInfo.type)}
            headerText={'Hazardous goods'}
            text={hazardousGoodsApprovalInfo.descriptionText}
            contentRight={
              booking.hazardousGoodsApproved && (
                <FlatButtonLink
                  to={routeCreators.hazardousGoods({ bookingNo })}
                  label={'See documents'}
                />
              )
            }
          />
        )}
        {showHazGoodsPaymentInformation && (
          <Banner
            variant={'info'}
            headerText={'Booking cannot be paid yet'}
            text={'Once the hazardous goods are confirmed the booking can be paid online.'}
          />
        )}

        {children}

        {miscInformation && <Banner variant={'warning'} text={miscInformation} />}

        <BookingUpdateStatusMessage
          onIgnoreChanges={revertChanges}
          onSetBookingStatusToWaitlist={updateBookingToWaitlist}
          submitting={submitting}
          updateSubmitInformation={updateSubmitInformation}
        />

        {statusInformationList.map((statusInformation, index) => (
          <Banner
            key={index}
            variant={resolveInformationMessageBannerVariant(statusInformation.type)}
            headerText={statusInformation.message}
          />
        ))}

        {hasPlugins && (
          <Banner
            variant={resolveInformationMessageBannerVariant(InformationMessageType.RegularInfo)}
            text={Texts.Plugin}
          />
        )}
      </Box>

      <Cardy indent={[0, 3]} width={'100%'} borderRadius={16}>
        <BookingColumnGrid>
          <BookingColumn>
            <SailingDetailsSection
              fieldsToShow={fieldsToShow}
              fieldsToEnable={fieldsToEnable}
              disabled={submitting}
              formState={formState}
              hasArrived={booking.hasArrived}
            />
          </BookingColumn>
          <BookingColumn>
            <VehicleDetailsSection
              fieldsToShow={fieldsToShow}
              fieldsToEnable={fieldsToEnable}
              formState={formState}
              disabled={submitting}
            />
          </BookingColumn>

          <BookingColumn>
            <CargoDetailsSection
              showCargoSection={!showCargoSection}
              fieldsToShow={fieldsToShow}
              fieldsToEnable={fieldsToEnable}
              disabled={submitting}
              booking={booking}
            />
            {showImportExportSection && (
              <ImportExportDetailsSection
                fieldsToShow={fieldsToShow}
                fieldsToEnable={fieldsToEnable}
                disabled={submitting}
                booking={booking}
              />
            )}
          </BookingColumn>
          <BookingColumn>
            <LoadingDetailsSection
              fieldsToShow={fieldsToShow}
              fieldsToEnable={fieldsToEnable}
              disabled={submitting}
              formState={formState}
              booking={booking}
            />
          </BookingColumn>
        </BookingColumnGrid>
      </Cardy>
    </BookingDetailsModalWrapper>
  );
};

const InitialGapHack = () => <div />;
